<template>
<section>
  <Down v-if="position === 'asc'" />
  <Up v-else-if="position === 'desc'" />
  <Ordenate v-else/>
</section>
</template>

<script>
import Ordenate from '@/assets/icons/ordenate.svg'
import Up from '@/assets/icons/arrow-up2.svg'
import Down from '@/assets/icons/arrow-down2.svg'

export default {
  components: {
    Ordenate, Up, Down
  },

  props: {
    position: String,
  }
}
</script>